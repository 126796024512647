@import "variables/variable";
@import "base/font";

body {
  font-family: $font-family;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

pre {
  font-family: $font-family;
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  margin: 0px;
}

/* New Style */
.p-container,
.p-container-fluid,
.p-container-xxl,
.p-container-xl,
.p-container-lg,
.p-container-md,
.p-container-sm {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  &.space-top {
    padding-top: 6rem;
  }
}

.p-w-100 {
  width: 100%;
}

.p-w-75 {
  width: 75%;
}

.p-w-50 {
  width: 50%;
}

.p-w-25 {
  width: 25%;
}

.p-text-primary {
  color: $primary-color;
}

.p-text-warning {
  color: $orange-color;
}

.p-text-danger {
  color: $red-color;
}

.p-text-white {
  color: $white-color;
}

.p-text-success {
  color: $green-color;
}

.p-button-block {
  width: 100%;
}

.p-font-size-md {
  font-size: 0.875rem;
}

.p-font-size-sm {
  font-size: 0.75rem;
}

/* Overwrite Style */
.p-component {
  font-family: $font-family;
  font-size: 1rem;
  font-weight: normal;
}

.p-inputtext {
  width: 100%;
  padding: 24px 12px 8px;
  font-family: $font-family;
  border-color: $light-hard-grey-color;
  color: $black-color;

  &:enabled {
    &:focus {
      border-color: $primary-color;
      box-shadow: inset 0 0 0 1px $primary-color;

      &.ng-invalid.ng-dirty {
        box-shadow: inset 0 0 0 1px $red-color;
      }
    }

    &:hover {
      border-color: $primary-color;
    }
  }

  &:disabled {
    border-color: #eaf0f5;
  }

  &.ng-invalid.ng-dirty {
    border-color: $red-color;
    box-shadow: inset 0 0 0 0px $red-color;

    & ~ label {
      color: $red-color;
    }
  }
}

.p-calendar {
  border-color: $light-hard-grey-color;

  &:enabled {
    &:focus {
      border-color: $primary-color;
      // box-shadow: inset 0 0 0 1px $primary-color;

      &.ng-invalid.ng-dirty {
        box-shadow: inset 0 0 0 1px $red-color;
      }
    }

    &:hover {
      border-color: $primary-color;
    }
  }

  &:disabled {
    border-color: #eaf0f5;
  }

  &.ng-invalid.ng-dirty {
    border-color: $red-color;
    box-shadow: inset 0 0 0 0px $red-color;

    & ~ label {
      color: $red-color;
    }
  }
}

.p-calendar-w-btn:not(.p-calendar-disabled) {
  &.p-focus {
    border: 1px solid $primary-color;
    box-shadow: none;
    box-shadow: inset 0 0 0 1px $primary-color;
  }

  &:hover {
    border-color: $primary-color;
  }
}

.p-calendar-w-btn {
  .p-datepicker-trigger.p-button {
    height: auto;

    .pi-calendar {
      background-image: url("../assets/icon/ic-calendar.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;

      &::before {
        content: none;
      }
    }
  }
}

p-calendar {
  &.ng-dirty.ng-invalid {
    .p-calendar-w-btn {
      border-color: $red-color;

      .p-datepicker-trigger.p-button {
        .pi-calendar {
          background-image: url("../assets/icon/ic-calendar-invalid.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;

          &::before {
            content: none;
          }
        }
      }
    }

    .p-inputtext {
      border-color: $red-color;
    }
  }
}

.ng-dirty {
  &.ng-touched {
    &.ng-invalid {
      & ~ label {
        color: $red-color;
      }

      & > .p-dropdown {
        & .pi-chevron-down {
          &:before {
            color: $red-color;
          }
        }
      }
    }
  }
}

.pi-chevron-down {
  &:before {
    color: $primary-color;
  }
}

small.p-error {
  color: $red-color;
}

.p-float-label {
  & label {
    margin-top: -0.5rem;
    line-height: 1.2;
    color: #8a9fab;
    background: transparent;
  }

  & input:disabled {
    opacity: 1;
    color: #5a6e7b;
    -webkit-text-fill-color: #5a6e7b;
    -moz--text-fill-color: #5a6e7b;
    -ms-text-fill-color: #5a6e7b;
  }

  & input.p-filled:disabled ~ label {
    color: #8a9fab;
    -webkit-text-fill-color: #8a9fab;
    -moz--text-fill-color: #8a9fab;
    -ms-text-fill-color: #8a9fab;
  }

  & p-dropdown.p-inputwrapper-filled ~ label[disabled] {
    color: #8a9fab !important;
    -webkit-text-fill-color: #8a9fab !important;
    -moz--text-fill-color: #8a9fab;
    -ms-text-fill-color: #8a9fab;
  }

  & input:focus ~ label,
  & .p-inputwrapper-focus ~ label {
    top: 1.3rem !important;
    margin-left: -6px;
    color: $primary-light-color;
    background: transparent;
    font-size: 0.875rem;
  }

  & input.p-filled ~ label,
  & .p-inputwrapper-filled ~ label {
    top: 1.3rem !important;
    margin-left: 0px;
    color: $primary-light-color;
    background: transparent;
    font-size: 0.875rem;
  }

  & input:focus,
  & .p-inputwrapper-focus {
    & ~ small.p-error ~ label {
      margin-top: 0;
      color: $red-color;
    }
  }

  & input.p-filled,
  & .p-inputwrapper-filled {
    & ~ small.p-error ~ label {
      margin-top: 0;
      color: $red-color;
    }
  }

  & small.p-error {
    margin: 0 12px;
    color: $red-color;
    display: block;

    & ~ label {
      margin-top: -1rem;
    }
  }

  & textarea:focus ~ label,
  & .p-textareawrapper-focus ~ label {
    top: 1.3rem !important;
    margin-left: 0px;
    color: $primary-light-color;
    background: transparent;
    font-size: 0.875rem;
  }

  & textarea.p-filled ~ label,
  & .p-textareawrapper-filled ~ label {
    top: 1.3rem !important;
    margin-left: 0px;
    color: $primary-light-color;
    background: transparent;
    font-size: 0.875rem;
  }
}

.p-button {
  height: 3rem;
  font-size: 1.125rem;
  font-weight: bold;
  background: linear-gradient(116deg, #00cef3, #0098c6 100%);

  & span {
    line-height: 1;
  }

  &:enabled:hover {
    // background: $primary-gradient-alpha-color-1;
    background: linear-gradient(
      0deg,
      $primary-light-color,
      $primary-light-color 100%
    );
  }

  &:enabled:active,
  &:enabled:focus {
    background: linear-gradient(116deg, #3ecbff, #3ecbff 100%);
  }

  &:disabled {
    box-shadow: none;
    color: $light-hard-grey-color !important;
    -webkit-text-fill-color: $light-hard-grey-color !important;
    -moz-text-fill-color: $light-hard-grey-color !important;
    -ms-text-fill-color: $light-hard-grey-color !important;
    // background: #eaf0f5 !important;
    // &.p-button-text {
    //     background: #eaf0f5 !important;
    // }
  }

  &.p-button-text {
    background: $white-color;
    color: $primary-color;

    &:enabled:hover {
      background: $white-alpha-color-1;
      color: $primary-color;
    }

    &:enabled:active,
    &:enabled:focus {
      background: $white-alpha-color-2;
    }
  }

  &.p-button-transparant {
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  &.p-button-outlined {
    border-color: #00a6e6;
    background: transparent;
    color: $primary-darkest-color;

    &:enabled:hover {
      background: $light-color;
      color: $primary-light-color;
      border-color: $primary-light-color;
    }

    &:enabled:focus {
      background: $white-color;
      color: $darkest-blue;
      border-color: $darkest-blue;
    }
  }
}

.p-checkbox {
  & .p-checkbox-box {
    border-width: 1px;
    border-color: $light-grey-color;
    border-radius: 2px;
    width: 24px !important;
    height: 24px;

    &.p-highlight {
      border-color: $primary-color;
      background: $primary-color;

      & .p-checkbox-icon {
        &.p-checkbox-icon {
          &::before {
            top: 7px;
            left: 2px;
          }
        }
      }

      &:not(.p-disabled).p-focus {
        border-color: $primary-color;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    & .p-checkbox-box.p-focus {
      border-color: $primary-color;
    }

    & .p-checkbox-box.p-highlight:hover {
      border-color: $primary-color;
      background: $primary-color;
    }
  }
}

p-dropdown {
  &.ng-dirty {
    &.ng-invalid {
      & .p-dropdown {
        border-color: $red-color;

        & .pi-chevron-down {
          &:before {
            color: $red-color;
          }
        }

        &:not(.p-disabled) {
          &.p-focus {
            box-shadow: inset 0 0 0 1px $red-color;
          }
        }
      }
    }
  }

  // &[ng-reflect-disabled="true"]~label {
  //     color: #8a9fab !important;
  //     -webkit-text-fill-color: #8a9fab !important;
  //     -moz-text-fill-color: #8a9fab !important;
  //     -ms-text-fill-color: #8a9fab !important;
  // }
  & ~ label#disabled {
    color: #8a9fab !important;
    -webkit-text-fill-color: #8a9fab !important;
    -moz-text-fill-color: #8a9fab !important;
    -ms-text-fill-color: #8a9fab !important;
  }
}

.p-dropdown {
  border-color: $light-hard-grey-color;

  &.pi-chevron-down {
    &:before {
      color: $primary-color;
    }
  }

  &.p-disabled {
    border-color: #eaf0f5;
    opacity: 1;

    & .p-dropdown-label {
      color: #5a6e7b;
    }

    & .pi-chevron-down:before {
      opacity: 0.38;
      color: #5a6e7b;
    }
  }

  & .p-dropdown-items-wrapper {
    max-height: 300px !important;
  }

  &:not(.p-disabled) {
    &.p-focus {
      border-color: $primary-color;
      box-shadow: inset 0 0 0 1px $primary-color;
    }

    &:hover {
      border-color: $primary-color;
    }
  }
}

.p-dropdown-panel {
  & .p-dropdown-items {
    & .p-dropdown-item.p-highlight {
      color: $primary-color;
      background: #0ca6df0e;
    }
  }
}

.p-disabled,
.p-component:disabled {
  opacity: 1;
  background: linear-gradient(0deg, #eaf0f5, #eaf0f5) !important;

  & .pi-chevron-down:before {
    color: $dark-grey-color;
    -webkit-text-fill-color: $dark-grey-color;
    -moz-text-fill-color: $dark-grey-color;
    -ms-text-fill-color: $dark-grey-color;
  }
}

.p-dialog {
  max-width: 360px;
  width: 90%;
}

.p-grid {
  display: block;
}

.footer {
  &.mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
}

.grecaptcha-badge {
  // z-index: -1;
  display: none;
}

ul.no-indent {
  list-style-position: inside !important;
}

ul.no-indent li::before {
  content: "";
  margin-left: -8px;
}

@media (max-width: 576px) {
  .p-dropdown .p-dropdown-panel {
    max-width: 100%;
    overflow-x: auto;

    .p-dropdown-item {
      white-space: break-spaces;
    }
  }
}

@media (min-width: 576px) {
  .p-container-sm,
  .p-container {
    max-width: 540px;
  }

  .p-dialog {
    min-width: 70%;
  }
}

@media (min-width: 768px) {
  .p-container-md,
  .p-container-sm,
  .p-container {
    max-width: 720px;
  }

  .p-dialog {
    min-width: 50%;
  }
}

@media (min-width: 992px) {
  .p-grid {
    display: flex;
  }

  .p-text-primary {
    text-align: left;
  }

  .p-container-lg,
  .p-container-md,
  .p-container-sm,
  .p-container {
    max-width: 960px;
  }

  .p-dialog {
    min-width: 40%;
  }
}

@media (min-width: 1200px) {
  .p-container-xl,
  .p-container-lg,
  .p-container-md,
  .p-container-sm,
  .p-container {
    max-width: 1140px;
  }

  .p-dialog {
    min-width: 20%;
  }
}

@media (min-width: 1400px) {
  .p-container-xxl,
  .p-container-xl,
  .p-container-lg,
  .p-container-md,
  .p-container-sm,
  .p-container {
    max-width: 1320px;
  }
}

.ic-vaccination {
  background-image: url("../assets/img/facilities/vaccination.svg");
}

.ic-wifi {
  background-image: url("../assets/img/facilities/wifi.svg");
}

.ic-car-park {
  background-image: url("../assets/img/facilities/car-park.svg");
}

.ic-toilet {
  background-image: url("../assets/img/facilities/toilet.svg");
}

.ic-air-conditioner {
  background-image: url("../assets/img/facilities/air-conditioner.svg");
}

.ic-wheel-chair {
  background-image: url("../assets/img/facilities/wheel-chair.svg");
}

.ic-pet {
  background-image: url("../assets/img/facilities/pet.svg");
}

.ic-child {
  background-image: url("../assets/img/facilities/child.svg");
}

.ic-smoke-area {
  background-image: url("../assets/img/facilities/smoke-area.svg");
}

.ic-reserve {
  background-image: url("../assets/img/facilities/reserve.svg");
}

.ic-outdoor {
  background-image: url("../assets/img/facilities/outdoor.svg");
}

.ic-indoor {
  background-image: url("../assets/img/facilities/indoor.svg");
}

.ic-work-area {
  background-image: url("../assets/img/facilities/working-space.svg");
}

.ic-alcohol {
  background-image: url("../assets/img/facilities/alcohol.svg");
}

.ic-music {
  background-image: url("../assets/img/facilities/music.svg");
}

.ic-meeting-room {
  background-image: url("../assets/img/facilities/meeting-room.svg");
}

.ic-ev-charger {
  background-image: url("../assets/img/facilities/power-station.svg");
}

.ic-swimming-pool {
  background-image: url("../assets/img/facilities/pool.svg");
}

.ic-food {
  background-image: url("../assets/img/facilities/restuarant.svg");
}

.ic-airport-transfer {
  background-image: url("../assets/img/facilities/airport-transfer.svg");
}

.ic-fitness {
  background-image: url("../assets/img/facilities/fitness.svg");
}

.ic-spa {
  background-image: url("../assets/img/facilities/spa.svg");
}

.ic-laundry {
  background-image: url("../assets/img/facilities/laundry.svg");
}

.ic-vehicle-rent {
  background-image: url("../assets/img/facilities/car-rent.svg");
}

.ic-sorting {
  background-image: url("../assets/icon/ic-sorting.svg");
}

.ic-entrance-fee {
  background-image: url("../assets/img/facilities/entrance-fee.svg");
}

.ic-warning-fill {
  background-image: url("../assets/icon/icon-warning-fill.svg");
}

.p-datepicker {
  margin-top: -8px;
  border: 1px solid $light-hard-grey-color;

  &:not(.p-datepicker-inline) {
    border: 1px solid $light-hard-grey-color;
  }

  .p-datepicker-header {
    display: -webkit-box;
    width: 100%;
    padding: 10px;
    padding-bottom: 15px;

    .p-datepicker-prev {
      color: $primary-color;

      .pi-chevron-left {
        font-size: 20px;
        font-weight: 100;
        background-image: url("../assets/icon/ic-chevron-left-calendar.svg");
        background-repeat: no-repeat;
        background-size: 11px 20px;
        width: 11px;
        height: 20px;

        &::before {
          content: none;
        }
      }
    }

    .p-datepicker-next {
      color: $primary-color;

      .pi-chevron-right {
        font-size: 20px;
        font-weight: 100;
        background-image: url("../assets/icon/ic-chevron-right-calendar.svg");
        background-repeat: no-repeat;
        background-size: 11px 20px;
        width: 11px;
        height: 20px;

        &::before {
          content: none;
        }
      }
    }

    .p-datepicker-title {
      margin-left: auto;
      margin-right: auto;
      font-weight: 700;
    }
  }

  .p-datepicker-calendar-container {
    .p-datepicker-calendar {
      margin: 0;

      th {
        color: $grey-color;
        font-weight: 700;
        padding-bottom: 16px;
      }

      td {
        padding: 0.5rem;

        span {
          width: 2rem;
          height: 2rem;
          font-size: 14px;
        }
      }
    }
  }
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: $primary-light-color;
  color: $white-color;
  cursor: pointer;
  border: none;
}

.p-datepicker table td > span.p-highlight {
  color: $white-color;
  background: $primary-color;
  border: none;
}

.p-datepicker table td > span.p-highlight:hover {
  color: $white-color;
  background: $primary-color;
  border: none;
}

.p-datepicker table td.p-datepicker-today > span {
  background: unset;
  color: $black-color;
  border-color: $light-hard-grey-color;
  box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: $white-color;
  background: $primary-color;
  border: none;
}

.p-datepicker {
  table {
    td {
      &:hover {
        cursor: not-allowed;
      }

      span {
        &.p-disabled {
          background: $white-color !important;
          color: $light-hard-grey-color;
          font-weight: 400;
        }
      }

      &.p-datepicker-other-month {
        span {
          color: $light-hard-grey-color;
        }

        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }
}

.btn-primary--gradient-color {
  background-color: $primary-gradient-color;
}

.p-checkbox {
  height: 24px;
  width: 24px;
}

.label-for-input {
  margin-top: 0.5rem !important;
}
