@import "../variables/variable";

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Krungthai Fast";
  src: url("../../assets/font/KrungthaiFast-UltraLightItalic.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fc-dark-light-gray {
  color: $dark-light-grey-color;
}

.fc-grey-light {
  color: $grey-light;
}

.fc-grey-color {
  color: $grey-color;
}

.fc-green-color-bg {
  background-color: $green-color;
}

.fc-green-color {
  color: $green-color;
}

.fc-dark-green-color {
  color: $dark-green-color;
}

.fc-light-hard-grey-color-bg {
  background-color: $light-hard-grey-color;
}

.fc-white-color {
  color: $white-color;
}

.fc-white-color-bg {
  background-color: $white-color;
}

.fc-primary-color {
  color: $primary-color;
}

.fc-orange-color {
  color: $orange-color;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
