/* You can add global styles to this file, and also import other style files */
$font-family: "Krungthai Fast", Roboto, Helvetica Neue Light, Helvetica Neue,
  Helvetica, Arial, Lucida Grande, sans-serif;

/* Color Variable */
$primary-darkest-color: #0ca6df;
$primary-dark-color: #059fce;
$primary-dark-light-color: #339dc6;
$primary-color: #0ca6df;
$primary-light-color: #3ecbff;
$primary-lighted-color: #a2e6ff;
$primary-lightest-color: #e6f6fb;
$primary-lightest-more-color: #e6f3f8;
$primary-blue-green: #53c3d2;

$black-color: #000000;
$darkest-color: #30323a;
$dark-grey-color: #565b6e;
$dark-light-grey-color: #5a6e7b;
$grey-color: #8a9fab;
$light-hard-grey-color: #bcccd6;
$light-grey-color: #dfe2f0;
$light-color: #fafafa;
$white-color: #ffffff;
$lightest-grey-color: #eff6fc;
$grey-light: #e1edf5;

$dark-green-color: #4f9800;
$green-color: #63be00;
$light-green-color: #82cb33;
$green-yellow: #00b663;

$dark-red-color: #af0000;
$red-color: #db0000;
$light-red-color: #e23333;

$dark-orange-color: #f08700;
$orange-color: #f49b00;
$light-orange-color: #f6af33;
$lighter-orange-color: #fff4e0;

$active-color: #eaf0f5;
$disable-color: #f2f3f6;
$active-red-color: #ff4f4f;

$bg-color: #eaf0f5;
$bg-table-color: #f2f3f6;

$dark-blue: #0962c5;
$darkest-blue: #0085b8;

$primary-gradient-color: linear-gradient(135deg, #00cef3 0%, #0098c6 100%);
$primary-gradient-alpha-color-1: linear-gradient(135deg, #00cef3d5, #0098c6d5);
$primary-gradient-alpha-color-2: linear-gradient(135deg, #00cef3b5, #0098c6b5);
$red-gradient-color: linear-gradient(135deg, #db0000, #af0000);
$red-gradient-alpha-color-1: linear-gradient(135deg, #db0000d5, #af0000d5);
$red-gradient-alpha-color-2: linear-gradient(135deg, #db0000b5, #af0000b5);
$green-gradient-color: linear-gradient(135deg, #63be00, #4f9800);
$green-gradient-alpha-color-1: linear-gradient(135deg, #63be00d5, #4f9800d5);
$green-gradient-alpha-color-2: linear-gradient(135deg, #63be00b5, #4f9800b5);
$orange-gradient-color: linear-gradient(135deg, #f49b00, #f08700);
$orange-gradient-alpha-color-1: linear-gradient(135deg, #f49b00d5, #f08700d5);
$orange-gradient-alpha-color-2: linear-gradient(135deg, #f49b00b5, #f08700b5);
$white-alpha-color-1: rgba(255, 255, 255, 0.92);
$white-alpha-color-2: rgba(255, 255, 255, 0.68);
